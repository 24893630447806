import classnames from 'classnames';
import objectHash from 'object-hash';
import { Field } from 'packages/formidable';
import { parse } from 'query-string';
import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import Actions, { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Button from '~/components/Button';
import { ListTabProps } from '~/components/Filter';
import FilterList from '~/components/Filter/List';
import { FilterType } from '~/components/Filter/types';
import Search from '~/components/Search';
import IconChevronDown from '~/icons/ChevronDown';
import IconEllipsisH from '~/icons/EllipsisH';

import ViewTitle from './Title';

const MyCustomInput: FC<{ input: any }> = ({ input: { value } }) => (
  <span>{value}</span>
);

interface TitleProps {
  className?: string;
  title: string | string[] | any;
}

const Title: FC<TitleProps> = ({ className, title }) => {
  if (Array.isArray(title)) {
    return (
      <ViewTitle className={className}>
        {title.map(item => (
          <Field
            key={`title_${item.attribut}`}
            component={MyCustomInput}
            disabled
            name={item.attribut}
          />
        ))}
      </ViewTitle>
    );
  }

  if ('object' === typeof title) {
    return (
      <ViewTitle className={className}>
        <Field component={MyCustomInput} disabled name={title.attribut} />
      </ViewTitle>
    );
  }

  return title ? <ViewTitle className={className}>{title}</ViewTitle> : null;
};

const Header: FC<{
  displaySearch?: boolean;
  filters?: FilterType[];
  menu?: MenuType;
  search?: string;
  subMenu?: (ActionProps | ActionProps[])[];
  subTitle?: ReactNode;
  tabIndex?: number;
  tabs?: ListTabProps[];
  title?: string | TitleProps;
}> = function ({
  displaySearch,
  filters,
  menu = { left: [], right: [] },
  search,
  subMenu,
  subTitle,
  tabIndex = 0,
  tabs,
  title,
}) {
  const [nbActions, setNbActions] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLUListElement>(null);
  const [openTabs, setOpenTabs] = useState<boolean>(false);
  const [openActions, setOpenActions] = useState<boolean>(false);
  const params = search ? parse(search) : undefined;

  const handleToggleOpenActionsOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setOpenActions(o => !o);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenActions(false);
    }
    if (tabRef.current && !tabRef.current.contains(event.target)) {
      setOpenTabs(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    const handleOnResize = () => {
      const { current } = divRef;
      if (current) {
        const useSpace = (current.offsetWidth - 24 - 36 - 36) / 4;
        setNbActions(Math.floor(useSpace / 36));
      }
    };
    window.addEventListener('resize', handleOnResize);

    handleOnResize();

    return () => {
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  const rightMenu = menu?.right ? [...menu.right] : [];

  const displayRightMenu = [...rightMenu.splice(0, nbActions)];

  if (rightMenu.length > 0 || (filters && filters.length > 0)) {
    displayRightMenu.push({
      icon: IconEllipsisH,
      label: 'Ouvrir la fenêtre avec les options',
      onClick: handleToggleOpenActionsOnClick,
    });
  }

  const handleOnClick = () => {
    setOpenTabs(o => !o);
  };

  return (
    <>
      <div
        ref={divRef}
        className={classnames(
          'sticky top-0  z-30 bg-background bg-opacity-95 md:-mx-6',
          // { 'border-b': false && !displaySearch },
        )}
      >
        <div
          className={classnames(
            'flex flex-row space-x-3 items-center',
            'py-1 px-3',
          )}
        >
          <nav className="md:mr-3">
            {menu && menu.left && menu.left.length > 0 && (
              <Actions items={menu.left} />
            )}
          </nav>

          <div className="flex-1 overflow-hidden">
            {title && <Title className="truncate" title={title} />}
          </div>

          {displayRightMenu.length > 0 && (
            <nav>
              <Actions items={displayRightMenu} />
              {openActions && (
                <div
                  ref={ref}
                  className="absolute z-[100] bg-dark-500 rounded-md top-[48px] right-3 md:right-6 min-w-[200px] max-w-popup-mobile md:max-w-popup-desktop overflow-y-auto"
                >
                  {rightMenu && rightMenu.length > 0 && (
                    <div>
                      <Actions
                        className="flex flex-col"
                        items={rightMenu}
                        variant="option"
                      />
                    </div>
                  )}
                  <FilterList items={filters} params={params} />
                </div>
              )}
            </nav>
          )}
        </div>

        {subMenu && subMenu.length > 0 && (
          <div className="pb-3 px-6">
            <Actions
              className="flex items-center space-x-1.5 overflow-x-auto rounded-[14px]"
              items={subMenu}
              variant="tab"
            />
          </div>
        )}
      </div>

      {displaySearch && <Search q={params?.q as string | undefined} />}
      {subTitle && <h2 className="px-6 pb-1.5 text-[22px]">{subTitle}</h2>}

      {tabs && (
        <div className="flex justify-center md:justify-start relative mt-3">
          <div
            className={classnames('flex items-center space-x-2 cursor-pointer')}
            onClick={handleOnClick}
            role="presentation"
          >
            <div>
              <IconChevronDown
                className={classnames('h-[12px] w-[12px]', {
                  'transform rotate-180': openTabs,
                })}
              />
            </div>
            <div className="flex-1">{tabs[tabIndex].label}</div>
          </div>
          {openTabs && (
            <div className="absolute z-[100] flex justify-center top-[33px] left-3 md:left-0 w-popup-mobile md:w-auto">
              <ul ref={tabRef} className="bg-dark-500 rounded px-6 py-3">
                {(Array.isArray(tabs) ? tabs : [tabs]).map((tab, index) => (
                  <li key={objectHash(tab)}>
                    <Button
                      className={classnames(
                        'focus:outline-none w-full text-light-600 focus:text-light-300 justify-center md:justify-start h-[32px]',
                        {
                          'is-active': tabIndex === index,
                        },
                      )}
                      data-index={index}
                      to={tab.pathname}
                      type="button"
                      variant="clear"
                    >
                      {tab.label}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
